<template>
	<main v-if="blog">
    <PreviousPage></PreviousPage>
		<div class="container">
			<section class="post" v-if="blog.template_model == 0">
				<div class="post__header">
					<p>{{ blog.creation_date }}</p>
					<h2>{{ blog.name }}</h2>
          <img v-lazy="blog.header.url" :srcset="blog.header.srcset" :sizes="this.$store.state.sizes" :alt="blog.header.alt">
				</div>
				<div class="post__body" v-if="blog.sub_content.lenght !== 0">
					<div class="part" v-for="sub in blog.sub_content">
						<h3>{{ sub.name }}</h3>
						<p v-html="sub.content"></p>
					</div>
        </div>
        <div class="recipe__body" v-if="blog.recipe.length !== 0" v-for="r in blog.recipe">
          <div class="column column__left">
            <h3>INGREDIENTS <span>(POUR {{ r.persons_number }} PERSONNES)</span></h3>
            <ul>
              <li v-for="i in r.ingredients">{{ i.formatted_name }}</li>
            </ul>

          </div>
          <div class="column column__right">
            <h3>PRÉPARATION</h3>
            <ol>
              <li v-for="s in r.steps">
                {{ s.action }}
              </li>
            </ol>
          </div>
        </div>
      </section>
      <section class="post" v-else-if="blog.template_model == 1">
        <div class="post__header">
          <p>{{ blog.creation_date }}</p>
          <img v-lazy="blog.header.url" :srcset="blog.header.srcset" :sizes="this.$store.state.sizes" :alt="blog.header.alt">
        </div>
        <div class="post__body" v-if="blog.sub_content.lenght !== 0">
          <div class="part" v-for="sub in blog.sub_content">
            <h3>{{ sub.name }}</h3>
            <p v-html="sub.content"></p>
          </div>
          <div class="recipe__body" v-if="blog.recipe.length !== 0" v-for="r in blog.recipe">
            <div class="column column__left">
              <h3>INGREDIENTS <span>(POUR {{ r.persons_number }} PERSONNES)</span></h3>
              <ul>
                <li v-for="i in r.ingredients">{{ i.formatted_name }}</li>
              </ul>

            </div>
            <div class="column column__right">
              <h3>PRÉPARATION</h3>
              <ol>
                <li v-for="s in r.steps">
                  {{ s.action }}
                </li>
              </ol>
            </div>
          </div>
          <div class="part">
            <div class="images-grid">
              <div class="image-container" v-for="image in blog.ecommerceimage_set">
                <img v-lazy="image.url" :srcset="image.srcset" :sizes="this.$store.state.sizes" :alt="image.alt">
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="post" v-else-if="blog.template_model == 2">
        <div class="post__header">
          <p>{{ blog.creation_date }}</p>
          <h2>{{ blog.name }}</h2>
        </div>
        <div class="post__body" v-for="(obj, index) in list">
          <div class="part image-left" v-if="index % 2 === 0">
            <div class="wrapper">
              <div class="image-container">
                <img v-lazy="obj.image.url" :srcset="obj.image.srcset" :sizes="this.$store.state.sizes" :alt="obj.image.alt">
              </div>
              <div class="text">
                <h3>{{ obj.sub_content.name }}</h3>
                <p>{{ obj.sub_content.content }}</p>
              </div>
            </div>
<!--            <a href="#" class="button full">Call to action</a>-->
          </div>
          <div class="part image-right" v-else>
            <div class="wrapper">
              <div class="image-container">
                <img v-lazy="obj.image.url" :srcset="obj.image.srcset" :sizes="this.$store.state.sizes" :alt="obj.image.alt"  >
              </div>
              <div class="text">
                <h3>{{ obj.sub_content.name }}</h3>
                <p>{{ obj.sub_content.content }}</p>
              </div>
            </div>
          </div>
          <div class="recipe__body" v-if="blog.recipe.length !== 0" v-for="r in blog.recipe">
            <div class="column column__left">
              <h3>INGREDIENTS <span>(POUR {{ r.persons_number }} PERSONNES)</span></h3>
              <ul>
                <li v-for="i in r.ingredients">{{ i.formatted_name }}</li>
              </ul>

            </div>
            <div class="column column__right">
              <h3>PRÉPARATION</h3>
              <ol>
                <li v-for="s in r.steps">
                  {{ s.action }}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
			<div class="more">
				<router-link class="button full" :to="{name: 'Shop', params: {category: 'huître-en-ligne'}}">
					<h3>COMMANDEZ NOS HUÎTRES</h3>
				</router-link>
			</div>
		</div>
	</main>
</template>

<script>
import {getAPI} from "@/services/axios-api";
import {mapActions, mapGetters} from "vuex";
const PreviousPage = () => import ("@/components/PreviousPage")

export default {
	name: "Post",
  beforeMount () {
    this.$store.dispatch('metaData/getMetaDataBlog', this.url)
  },
  metaInfo() {
    return {
      title : this.getMetaBlog.category,
      meta:[
        // meta data to google/edge/fireFox...
        { name: 'title', content: this.getMetaBlog.meta_title},
        { name: 'description', content: this.getMetaBlog.meta_desc},
        { name: 'robots', content: 'index,follow'},
        // to SEO FaceBook & Linkedin
        { property: 'og:type', content:"website"},
        { property: 'og:url', content: "https://lafamilleboutrais.com/"},
        { property: 'og:title', content: this.getMetaBlog.meta_title},
        { property: 'og:description', content: this.getMetaBlog.meta_desc},
        // { property: 'og:image', content: this.getMetaBlog.ecommerceimage_set[0].url },
      ],
    }
  },
  components:{PreviousPage},
	props: {
		url: {
			type: [String, Number],
			required: true,
		}
	},
	data() {
		return {
			blog: undefined,
		}
	},
  computed: {
    ...mapGetters('metaData', ['getMetaBlog']),
    list: function () {
      if (this.blog.template_model == 2) {
        return this.blog.sub_content.map((itm, i) => {
          return {'sub_content': itm, 'image': this.blog.ecommerceimage_set[i]}
        })
      }
      return []
    }
  },
	mounted() {
		this.getBlogById();
	},
	methods: {
    ...mapActions('metaData', ['getMetaDataBlog']),
    replace: function (event, str) {
      event.target.src = str.replace('https://odoo.lafamilleboutrais.group', 'http://51.178.140.145:8090')
    },
		getBlogById: async function () {
			await getAPI
				.get('/ecommerce/' + this.url + '/')
				.then(response => {
					this.blog = response.data;
          if (this.blog.template_model !== 2) {
            this.blog['header'] = this.blog.ecommerceimage_set.shift()
          }
				})
				.catch(errors => {
					console.log(errors);
				})
		}
	}
}
</script>

<style lang="scss" scoped>
.container {
	@include flex(flex-start, flex-start, column, $gap: 20px);
	position: relative;
	width: 90vw;
	max-width: 1200px;
	margin: 0 auto;
	@media screen and (min-width: 992px) {
		width: 90vw;
	}
	@media screen and (min-width: 1200px) {
		width: 80vw;
	}
}


.recipe {
	width: 100%;

	&__header {
		margin: 25px auto 20px;
		@include flex(center, center, column, $gap: 20px);
		width: 100%;

		@media screen and (min-width: 992px) {
			margin: 50px auto 100px;
		}

		h2 {
			text-align: center;
			color: $Brown;
		}

		img {
			object-fit: contain;
			width: 100%;
			max-height: 400px;

			@media screen and (min-width: 992px) {
				max-height: 600px;
				max-width: 100%;
			}
		}
	}

	&__body {
		@include flex(center, center, column, $gap: 50px);
		width: 100%;
		margin: 50px 0;

		@media screen and (min-width: 992px) {
			align-items: flex-start;
			flex-direction: row;
		}

		.column {
			position: relative;
			font-size: 20px;
			flex: 1;
			width: 100%;

			&::before {
				@include position(0, 0);
				margin-right: 20px;
				width: 5px;
				height: 100%;
				background-color: $Brown;
			}

			h3 {
				padding-left: 30px;
				font-size: 20px;
				font-weight: 600;
				text-transform: uppercase;
				color: $Brown;

				span {
					font-style: italic;
				}
			}

			ul, ol {
				padding-left: 30px;
				font-size: 16px;

				li {
					@include flex(flex-start, flex-start, $gap: 5px);
					margin: 15px 0;
					line-height: 1.4;
					position: relative;
				}
			}

			ul li {
				&::before {
					content: '';
					@include fixed-size(5px);
					background-color: $Brown;
					align-self: center;
					border-radius: 10px;
				}
			}

			ol {
				counter-reset: item;

				li {
					counter-increment: item;

					&::before {
						display: inline;
						width: 1em;
						font-weight: bold;
						text-align: right;
						content: counter(item) ".";
					}
				}
			}
		}
	}
}

.post {
	width: 100%;

	&__header {
		margin: 25px auto 20px;
		@include flex(center, center, column, $gap: 20px);

		@media screen and (min-width: 768px) {
			margin: 40px auto 75px;
		}

		@media screen and (min-width: 992px) {
			margin: 50px auto 100px;
		}

		h2 {
			text-align: center;
			color: $Brown;
		}

		img {
			object-fit: contain;
			width: 100%;
			max-height: 300px;

			@media screen and (min-width: 992px) {
				max-height: 450px;
				max-width: 800px;
			}
		}
	}

	&__body {
		.part {
			margin-bottom: 30px;

			@media screen and (min-width: 768px) {
				margin-bottom: 60px;
			}

			@media screen and (min-width: 992px) {
				margin-bottom: 75px;
			}

			h3 {
				margin-top: 25px;
				font-size: 20px;
				text-transform: uppercase;
			}

			p {
				margin: 10px 0;
				font-size: 16px;
				line-height: 1.6;
			}
		}
	}
}

// template Image grid
.images-grid {
	@include flex(center, flex-start, column, $gap: 5vw);

	@media screen and (min-width: 768px) {
		gap: 50px;
		flex-direction: row;
		flex-wrap: wrap;
	}

	.image-container {
		@include flex(center, center);
		width: 100%;
		max-width: 100%;
		position: relative;
		overflow: hidden;
		border-radius: 12.5px;

		@media screen and (min-width: 768px) {
			max-width: calc(50% - 25px);
		}

		img {
			width: 100%;
			object-fit: cover;
		}
	}
}

// Image on side
.image-right, .image-left {
	.wrapper {
		@include flex(center, center, $gap: 20px);

		.image-container {
			flex: 1;
			width: 100%;
			max-width: 100%;
			position: relative;
			overflow: hidden;
			border-radius: 12.5px;

			@media screen and (min-width: 768px) {
				max-width: calc(50% - 25px);
			}

			img {
				width: 100%;
				object-fit: cover;
			}
		}

		.text {
			flex: 1;

			h3 {
				text-align: center;
				margin: 0;
			}
		}

		@media screen and (max-width: 767.99px) {
			flex-direction: column !important;
		}
	}

	a {
		width: fit-content;
		margin: 30px auto 0;
	}
}

.image-right .wrapper {
	flex-direction: row-reverse;
}

</style>